/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MelcoModelsSelectListItem,
  MelcoModelsSelectListItemFromJSON,
  MelcoModelsSelectListItemFromJSONTyped,
  MelcoModelsSelectListItemToJSON,
} from "./";

/**
 *
 * @export
 * @interface MelcoFusionModelsFusionDefaultAppSetting
 */
export interface MelcoFusionModelsFusionDefaultAppSetting {
  /**
   *
   * @type {Array<MelcoModelsSelectListItem>}
   * @memberof MelcoFusionModelsFusionDefaultAppSetting
   */
  measurements?: Array<MelcoModelsSelectListItem> | null;
  /**
   *
   * @type {Array<MelcoModelsSelectListItem>}
   * @memberof MelcoFusionModelsFusionDefaultAppSetting
   */
  emb_formats?: Array<MelcoModelsSelectListItem> | null;
  /**
   *
   * @type {number}
   * @memberof MelcoFusionModelsFusionDefaultAppSetting
   */
  max_canvas_size?: number;
  /**
   *
   * @type {number}
   * @memberof MelcoFusionModelsFusionDefaultAppSetting
   */
  min_image_size?: number;
}

export function MelcoFusionModelsFusionDefaultAppSettingFromJSON(
  json: any
): MelcoFusionModelsFusionDefaultAppSetting {
  return MelcoFusionModelsFusionDefaultAppSettingFromJSONTyped(json, false);
}

export function MelcoFusionModelsFusionDefaultAppSettingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MelcoFusionModelsFusionDefaultAppSetting {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    measurements: !exists(json, "measurements")
      ? undefined
      : json["measurements"] === null
      ? null
      : (json["measurements"] as Array<any>).map(
          MelcoModelsSelectListItemFromJSON
        ),
    emb_formats: !exists(json, "emb_formats")
      ? undefined
      : json["emb_formats"] === null
      ? null
      : (json["emb_formats"] as Array<any>).map(
          MelcoModelsSelectListItemFromJSON
        ),
    max_canvas_size: !exists(json, "max_canvas_size")
      ? undefined
      : json["max_canvas_size"],
    min_image_size: !exists(json, "min_image_size")
      ? undefined
      : json["min_image_size"],
  };
}

export function MelcoFusionModelsFusionDefaultAppSettingToJSON(
  value?: MelcoFusionModelsFusionDefaultAppSetting | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    measurements:
      value.measurements === undefined
        ? undefined
        : value.measurements === null
        ? null
        : (value.measurements as Array<any>).map(
            MelcoModelsSelectListItemToJSON
          ),
    emb_formats:
      value.emb_formats === undefined
        ? undefined
        : value.emb_formats === null
        ? null
        : (value.emb_formats as Array<any>).map(
            MelcoModelsSelectListItemToJSON
          ),
    max_canvas_size: value.max_canvas_size,
    min_image_size: value.min_image_size,
  };
}
