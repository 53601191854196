/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MelcoModelsDesignDownloadDailySummary
 */
export interface MelcoModelsDesignDownloadDailySummary {
  /**
   *
   * @type {Date}
   * @memberof MelcoModelsDesignDownloadDailySummary
   */
  date?: Date;
  /**
   *
   * @type {number}
   * @memberof MelcoModelsDesignDownloadDailySummary
   */
  unique_files?: number;
  /**
   *
   * @type {number}
   * @memberof MelcoModelsDesignDownloadDailySummary
   */
  total_files?: number;
  /**
   *
   * @type {string}
   * @memberof MelcoModelsDesignDownloadDailySummary
   */
  user_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof MelcoModelsDesignDownloadDailySummary
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof MelcoModelsDesignDownloadDailySummary
   */
  last_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof MelcoModelsDesignDownloadDailySummary
   */
  id?: string | null;
}

export function MelcoModelsDesignDownloadDailySummaryFromJSON(
  json: any
): MelcoModelsDesignDownloadDailySummary {
  return MelcoModelsDesignDownloadDailySummaryFromJSONTyped(json, false);
}

export function MelcoModelsDesignDownloadDailySummaryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MelcoModelsDesignDownloadDailySummary {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    date: !exists(json, "date") ? undefined : new Date(json["date"]),
    unique_files: !exists(json, "unique_files")
      ? undefined
      : json["unique_files"],
    total_files: !exists(json, "total_files") ? undefined : json["total_files"],
    user_name: !exists(json, "user_name") ? undefined : json["user_name"],
    first_name: !exists(json, "first_name") ? undefined : json["first_name"],
    last_name: !exists(json, "last_name") ? undefined : json["last_name"],
    id: !exists(json, "id") ? undefined : json["id"],
  };
}

export function MelcoModelsDesignDownloadDailySummaryToJSON(
  value?: MelcoModelsDesignDownloadDailySummary | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    date: value.date === undefined ? undefined : value.date.toISOString(),
    unique_files: value.unique_files,
    total_files: value.total_files,
    user_name: value.user_name,
    first_name: value.first_name,
    last_name: value.last_name,
    id: value.id,
  };
}
