/* tslint:disable */
/* eslint-disable */
/**
 * Melco Reporting APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportingMelcoMachineStatisticsModelsValidateResponse
 */
export interface ReportingMelcoMachineStatisticsModelsValidateResponse {
    /**
     * 
     * @type {string}
     * @memberof ReportingMelcoMachineStatisticsModelsValidateResponse
     */
    view_id?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReportingMelcoMachineStatisticsModelsValidateResponse
     */
    is_password_required?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ReportingMelcoMachineStatisticsModelsValidateResponse
     */
    updated_date?: Date | null;
}

export function ReportingMelcoMachineStatisticsModelsValidateResponseFromJSON(json: any): ReportingMelcoMachineStatisticsModelsValidateResponse {
    return ReportingMelcoMachineStatisticsModelsValidateResponseFromJSONTyped(json, false);
}

export function ReportingMelcoMachineStatisticsModelsValidateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportingMelcoMachineStatisticsModelsValidateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'view_id': !exists(json, 'view_id') ? undefined : json['view_id'],
        'is_password_required': !exists(json, 'is_password_required') ? undefined : json['is_password_required'],
        'updated_date': !exists(json, 'updated_date') ? undefined : (json['updated_date'] === null ? null : new Date(json['updated_date'])),
    };
}

export function ReportingMelcoMachineStatisticsModelsValidateResponseToJSON(value?: ReportingMelcoMachineStatisticsModelsValidateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'view_id': value.view_id,
        'is_password_required': value.is_password_required,
        'updated_date': value.updated_date === undefined ? undefined : (value.updated_date === null ? null : value.updated_date.toISOString()),
    };
}


